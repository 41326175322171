// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-component=Map] {
  position: absolute;
  width: 100%;
  height: 100%;
}
[data-component=Map] .leaflet-container {
  height: 100%;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Map.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AACF;AAAE;EACE,YAAA;EACA,WAAA;AAEJ","sourcesContent":["[data-component='Map'] {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  .leaflet-container {\n    height: 100%;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
